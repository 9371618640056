<!-- =========================================================================================
    File Name: tableFilterSorter.vue
    Description: Add filter and sorting functionality to table
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
    <vx-card>
      <h3 v-if="add_new">Add Employee</h3>
      <h3 v-if="add_update">Edit Employee</h3>
      <h3 v-if="add_profile">Edit Profile</h3>


      <!-- Avatar Row -->
      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="flex items-start flex-col sm:flex-row profile_camera_icon">

          <div class="new_file_upld">
           <img :src="formData['image_url']||'/75x75.png'" class="rounded h-32 w-32" />
               <div class="blt_buttons"  v-if="add_Avatar">
                  <input type="file" class="hidden" ref="update_avatar_input" @change="update_avatar" accept=".png,.jpg,.jpeg">
                  <vs-button class="" v-if="add_Avatar" @click="$refs.update_avatar_input.click()"><feather-icon icon="CameraIcon" class="inline-block" svgClasses="w-4 h-4" /></vs-button>
            </div>
               <div class="blt_buttons" v-if="remove_Avatars">
                  <input type="file" class="hidden" ref="update_avatar_input" @change="remove_avatar" accept=".png,.jpg,.jpeg">
                  <vs-button class="" color="danger" v-if="remove_Avatars" @click="$refs.update_avatar_input.click()"><feather-icon icon="CameraIcon" class="inline-block" svgClasses="w-4 h-4" /></vs-button>
            </div>
          </div>
           
         
          </div>
        </div>
      </div>

      <!-- Content Row -->
      <div class="vx-row">
        <div class="vx-col md:w-1/2 w-full">
          <vs-input class="w-full mt-4 required" label="First Name " :maxlength=40  placeholder="First Name"  v-model="formData['first_name']" v-validate="'required'" name="first_name" />
          <span class="text-danger text-sm"  v-show="errors.has('first_name')">{{ errors.first('first_name') }}</span>

          

          <vs-input class="w-full mt-4" :maxlength=40 label="Last Name"  placeholder="Last Name" v-model="formData['last_name']" name="last_name" />
          <span class="text-danger text-sm"  v-show="errors.has('last_name')">{{ errors.first('last_name') }}</span>

         

          <vs-input class="w-full mt-4 required"   @input="checkPassword" label="Password" :maxlength=128  v-if="validate_password"  placeholder="Password" v-model="formData['password']" type="password" name="password" />
          <span class="text-danger text-sm" v-if="show_password_err_msg">Password Must Be Combination Of 8 Characters Must Includes One Number,Uppercase,Special Character..!!</span>
        </div>

        <div class="vx-col md:w-1/2 w-full">
         <!-- dateFormat: 'd F Y' , -->
          <div class="mt-4" hidden>
          <label class="text-sm" style="margin-left: 4px;">Date of Birth</label>
            <flat-pickr v-model="dob_model" placeholder = "Date of Birth" :config="{ maxDate: new Date() }" class="w-full" name="date_of_birth" />
          </div>

          <vs-input class="w-full mt-4" :maxlength=40  label="Middle Name" placeholder="Middle Name" v-model="formData['middle_name']" name="middle_name" />
          <span class="text-danger text-sm"  v-show="errors.has('middle_name')">{{ errors.first('middle_name') }}</span>

           <vs-input class="w-full mt-4 required" v-if="canel_btn" :disabled="!isDiabled" :maxlength=40 label="Email" placeholder="Email" v-model="formData['email']" type="email" v-validate="'required|email'" name="email" />

           <vs-input class="w-full mt-4 required" v-if="reset_btn" :maxlength=320 label="Email" placeholder="Email" v-model="formData['email']" type="email" v-validate="'required|email'" name="email" />
          <span class="text-danger text-sm"  v-show="errors.has('email')">{{ errors.first('email') }}</span>
         
          <!-- <vs-input class="w-full mt-4" label="Weight (KG)"  placeholder="Weight (KG)" v-model="formData['weight']" type="hidden" name="weight" />

          <vs-input class="w-full mt-4"  label="Height (Feet)" placeholder="Height (Feet)" v-model="formData['height']" type="hidden" name="height" /> -->

          <div class="mt-4">
            <label class="text-sm" style="margin-left: 4px;">Status</label>
            <vs-switch color="success" v-model="status_model">
                <span slot="on">Active</span>
                <span slot="off">InActive</span>
            </vs-switch>
          </div>
        </div>
      </div>

      <!-- Interests -->
      <div class="vx-row mt-4"  v-if="this.add_new">
        <div class="vx-col w-full">
          <div class="flex items-end ">
            <feather-icon svgClasses="w-6 h-6" icon="LockIcon" class="mr-2" />
              <label class="font-medium text-lg leading-none required" style="margin-left: 4px;">Interests <span class="text-danger">*</span></label>
          </div>
          <vs-divider />
        </div>
      </div>

      <div class="block overflow-x-auto"  v-if="this.add_new">
        <table>
          <tr v-for="m in modules" style="border-bottom: 1px solid red !important">
            <th class="">{{ m.module_name }}</th>
            <td v-for="(c, checkVal) in main_categories(m.module_id)" class="px-3 py-2">
              <vs-checkbox :value="initVals(m.module_id,c.category_id)"  v-bind:checked="checkedValues" v-model="c['checkVal']" @change="intrClicked(m.module_id,m.module_name,c.category_id,c.category_name)">{{c.category_name}}</vs-checkbox>
            </td>
          </tr>
        </table>
      </div>
 
      <!-- Save & Reset Button -->
      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="mt-8  flex-wrap items-center justify-end">
            <vs-button class="ml-auto mt-2" @click="save_changes" :disabled="!validateForm">Save Changes</vs-button>
             <vs-button class="ml-4 mt-2" v-if="reset_btn" color="warning" @click="reset_data">Reset</vs-button>
            <vs-button class="ml-4 mt-2" v-if="canel_btn" color="warning" @click="cancel_data">Cancel</vs-button>
          </div>
        </div>
      </div>

    </vx-card>
</template>

<script>
// import moduleEmployee          from '@/store/employee/moduleEmployee.js'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

export default {
  data () {
    return {
      promptName      : 'Edit Profile',
      formData        : {},
      interests       : [],
       add_Avatar     : true,
       remove_Avatars : '',
       success        : '',
       upload_image   : 0,
       add_new        : true,
       add_update     : false,
       add_profile    : false,
       validate_password : true,
       reset_btn : true,
       canel_btn : false,
        valid_password:'',
        show_password_err_msg :false,
        checkedValues:false,
        interest_ids:true,
        checkedInterset:0,
        errImageupload:''
            }
  },
  components: {
    vSelect,
    flatPickr
  },
  computed: {
    employee ()     { 
      if(!this.$route.params.id){
        return {"middle_name":"","last_name":"","interest_ids":"","org_id":this.$store.state.AppActiveUser.org_id,"height":"","weight":"","country_id":this.$store.state.AppActiveUser.country_id,"first_name":"",'status':false,'image_url':'/75x75.png'};
      }else if('my_profile' == this.$route.params.id){
         this.remove_Avatars  = true;
            this.add_Avatar   = false;
            this.upload_image =  1
            this.add_new      = false
            this.add_update   = false
            this.add_profile  = true
            this.validate_password= false
             this.reset_btn = false
             this.canel_btn = true
            this.checkedInterset = 1
        return this.$store.state.AppActiveUser
      }else{
        var getEmployeeDetails =  this.$store.getters['employee/getEmployee'](this.$route.params.id)
        if(getEmployeeDetails.image_url != ""){
            this.remove_Avatars = true;
            this.add_Avatar = false;
            
        }
         if(this.formData.image_url != ""){
            this.upload_image     =  1
            this.add_new          = false
            this.add_update       = true
            this.validate_password= false
            this.reset_btn = false
            this.canel_btn = true
            this.renderCount = true
            this.interest_ids = false
            this.checkedInterset = 1
        }
        return this.$store.getters['employee/getEmployee'](this.$route.params.id)
      }
    },
    countries () { 
      return this.$store.getters['country/getActiveCountries']
    },
    modules () { 
      return this.$store.getters['module/getActiveModules_employee']
    },
    categories () { 
      return this.$store.getters['category/queriedCategories']
    },
    status_model: {
      get () {
        return this.formData['status']=='1'?true:false
      },
      set (status) {
        this.formData['status'] = status?'1':'0'
      }
    },
    dob_model: {
      get () {
        return this.formData['date_of_birth']
      },
      set (date) {
        this.formData['date_of_birth'] = date
      }
    },
    isDiabled () {
      return false
    },
    validateForm () {
      if(this.validate_password == true){
        return !this.errors.any() && this.checkedInterset == 1 && this.valid_password == 1 && this.formData.first_name !== '' && this.formData.first_name  && this.formData.email !== '' && this.formData.email  && this.upload_image == 1
      }else{
         return !this.errors.any() && this.checkedInterset == 1  && this.formData.first_name !== '' && this.formData.first_name  && this.formData.email !== '' && this.formData.email && this.upload_image == 1
      }
    }
  },
  methods: {
    checkPassword() {
      this.password_length = this.formData['password'].length;
			const format = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
      if (this.password_length > 8) {
        this.contains_eight_characters = true;
      } else {
        this.contains_eight_characters = false;
			}
			
      this.contains_number = /\d/.test(this.formData['password']);
      this.contains_uppercase = /[A-Z]/.test(this.formData['password']);
			this.contains_special_character = format.test(this.formData['password']);
      
      if (this.contains_eight_characters === true && this.contains_special_character === true && this.contains_uppercase === true && this.contains_number === true) {
						this.valid_password = true;		
            this.show_password_err_msg = false	
      } else {
            this.show_password_err_msg = true	
            this.valid_password = false;
      }
    },
     cancel_data(){
      if ('my_profile' === this.$route.params.id) {
        this.$router.push('/employee/my_profile/').catch(() => {})
      }else{
        this.$router.push('/employee/all/').catch(() => {})
      }
    },
    main_categories (moduleId) {
      return this.$store.getters['category/getMainCategories'](moduleId)
    },
   initVals (moduleId,categoryId) { 
     if(this.interest_ids == false){
      const c =   {"module_id":moduleId,"category_ids":categoryId}
      var str1 =  this.formData['interest_ids'];
      var str1 =this.formData['interest_ids'].toString();
        var str2 = JSON.stringify(c);
        if(this.$route.name == "Employee"){
          if(str1.indexOf(str2) != -1){
              this.checkedValues = true
          }else{
             this.checkedValues = false
          }
        }
     }

    },
    intrClicked (moduleId,moduleName,categoryId,categoryName) {
      this.interest_ids = true
      this.interests = Array.isArray(this.interests)?this.interests:[]
      let indexModule = this.interests.findIndex((m)=>m.module_id==moduleId)
      if(indexModule==-1){
        const m = {"module_id":moduleId,"category_ids":categoryId}
        this.interests.push(Object.assign({}, m))
        indexModule = this.interests.length-1
      } else{
        var existingCategory = this.interests[indexModule]['category_ids'].toString();
        var newCatSet = existingCategory+','+categoryId;
        this.interests[indexModule]['category_ids'] = newCatSet;
         var splitArray = existingCategory.split(',');
        for(let i=0;i<splitArray.length;i++)
        {
            if(splitArray[i] == categoryId)
            {
                splitArray.splice(i, 1);
                if(splitArray.toString() !== "" ){
                  this.interests[indexModule]['category_ids'] =splitArray.toString();
                }else{
                  this.interests.splice(indexModule, 1);
                }
            }
            else{
                 var newCatSet = existingCategory+','+categoryId;
                this.interests[indexModule]['category_ids'] = newCatSet;
            }
        }
      }
     if(this.formData['interest_ids'].length == 2){
          this.checkedInterset = 0
      }else{
        this.checkedInterset = 1
      }
      this.formData['interest_ids'] = JSON.stringify(this.interests);
    },
   
    save_changes () {
      // if (!this.validateForm) return
      if(this.formData['date_of_birth'] == undefined){
       this.formData['date_of_birth'] =new Date()
      }
       this.formData['status_type'] = 1
        this.formData['employee_type'] = "2"
          this.formData['active_status'] = "1"
      this.$validator.validateAll().then(result => {
       // if (result) {
          const d = new Date(this.formData['date_of_birth'])
          this.formData['date_of_birth'] = d.getFullYear() + '-' + (d.getMonth()+1) + '-' + d.getDate() + ' 00:00:00'
         

          delete this.formData['date_of_join']
          delete this.formData['last_loggedin']
           this.$vs.loading()
          if(!this.$route.params.id){
            this.$store.dispatch('employee/addEmployee', Object.assign({}, this.formData)).then((res) => {
              this.$store.dispatch('employee/fetchEmployees')
              this.$vs.loading.close()  
              const success = 'Employee added successfully...!!!'
              this.notif_sucess(success) 
            }).catch((error) => { 
               if( error.message == "Request failed with status code 423"){
                          error.message = 'Employee is created by same email..!!!'
                      }else{
                          error = error.message
                      }
                  this.$vs.loading.close()
                  this.notif(error)     
                })
          }else if('my_profile' == this.$route.params.id){
              this.formData['interest_ids'] = JSON.stringify(this.formData['interest_ids']);
            this.$store.dispatch('employee/updateProfile', Object.assign({}, this.formData)).then((res) => {
              this.$vs.loading.close()  
            }).catch((error) => { 
             
                  this.$vs.loading.close()
                  this.notif(error)     
                })
          }else{
              this.formData['interest_ids'] = JSON.stringify(this.formData['interest_ids']);
            this.$store.dispatch('employee/updateEmployee', Object.assign({}, this.formData)).then((res) => {
              this.$vs.loading.close()  
              const success = 'Employee Updated successfully...!!!'
              this.notif_sucess(success) 
            }).catch((error) => {
                 this.$vs.loading.close()
                 this.notif(error) 
            })
         // }
        }
      })
    },
      notif (error) {
      this.$vs.notify({
        title: 'Alert',
        text: error.message,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'danger'
      })
    },
      notif_sucess (success) {
      this.$vs.notify({
        title: 'success',
        text: success,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color:"success"
      })
    },
    reset_data () {
       document.location.href="/employee/new";
      this.formData = Object.assign({}, this.employee)
      this.interests = JSON.parse(this.formData['interest_ids']||'[]')
      delete this.formData['employee_type']
      delete this.formData['date_of_join']
      delete this.formData['last_loggedin']
    },
       notif_failed (error) {
      this.$vs.notify({
        title: 'Alert',
        text: error,
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color:"danger"
      })
    },
  update_avatar (e) {
    this.$vs.loading()
                let file = e.target.files[0];
                 const fileSize = file.size
                var sizeInMB = (fileSize / (1024*1024)).toFixed(2);
                let reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = e => {
                    const src = e.target.result;
                    const img = new Image();
                    img.onload = () => {
                     if((img.height !== 75 || img.width !== 75) || (sizeInMB > 2)){
                      //   if(sizeInMB > 2)
                      // {
                            this.$refs.update_avatar_input.value = ''
                            this.errImageupload = 1
                            this.$vs.loading.close() 
                            const error = "The uploaded image size should be below 2 MB and its resolution should be 75*75 pixel..!!"
                            this.notif_failed(error)
                      }else{
                            this.errImageupload = 0
                      }
                    };
                    img.src = src;
                };
    setTimeout(() => {
      if(this.errImageupload == 0){
      this.$store.dispatch('employee/uploadImage', {image:e.target.files[0], tag_id:this.$route.params.id}).then((res)=>{
         this.formData['image_url'] = res
        if('my_profile' == this.$route.params.id){
          localStorage.setItem('image_url', res)
        }
        this.remove_Avatars = true;
        this.add_Avatar = false;
        this.upload_image = 1
         this.$refs.update_avatar_input.value = ''
        this.$vs.loading.close()
      }).catch((error) => { 
                  this.$vs.loading.close()
                  this.notif(error)     
         })
      }
    }, 200);
  },
    remove_avatar(e) {
      this.$vs.loading()
                let file = e.target.files[0];
                 const fileSize = file.size
                var sizeInMB = (fileSize / (1024*1024)).toFixed(2);
                let reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = e => {
                    const src = e.target.result;
                    const img = new Image();
                    img.onload = () => {
                      if((img.height !== 75 || img.width !== 75) || (sizeInMB > 2)){
                      //   if(sizeInMB > 2)
                      // {
                            this.$refs.update_avatar_input.value = ''
                            this.errImageupload = 1
                            this.$vs.loading.close() 
                            const error = "The uploaded image size should be below 2 MB and its resolution should be 75*75 pixel..!!"
                            this.notif_failed(error)
                      }else{
                            this.errImageupload = 0
                      }
                    };
                    img.src = src;
                };
    setTimeout(() => {
      if(this.errImageupload == 0){
      this.$store.dispatch('employee/removeImage', {image:e.target.files[0], tag_id:this.$route.params.id,oldImage:this.formData['image_url']}).then((res)=>{
        this.formData['image_url'] = res
        if('my_profile' == this.$route.params.id){
          localStorage.setItem('image_url', res)
        }
        this.remove_Avatars = true;
        this.add_Avatar = false;
        this.upload_image = 1
         this.$refs.update_avatar_input.value = ''
        this.$vs.loading.close()
      }).catch((error) => { 
                  this.$vs.loading.close()
                  this.notif(error)     
         })
          }
    }, 200);
    }
  },
  created () {
    // this.$store.registerModule('employee', moduleEmployee)
    if(!this.employee){
      this.$store.dispatch('employee/fetchEmployees').then((res)=>{
        this.formData = Object.assign({}, this.employee)
        this.interests = JSON.parse(this.formData['interest_ids']||'[]')
      }).catch((error) => this.notif(error))
    }else{
      this.formData = Object.assign({}, this.employee)
     // this.interests = JSON.parse(this.formData['interest_ids']||'[]')
    }
    if(this.countries.length==0){
      this.$store.dispatch('country/fetchCountries')
    }
    if(this.modules.length===0){
      this.$store.dispatch('module/fetchModules')
    }
    if(this.categories.length==0){
      this.$store.dispatch('category/fetchCategories')
    }
   //this.formData['date_of_birth'] =new Date()
  },
  beforeDestroy() {
    // this.$store.unregisterModule('employee')
  }
}
</script>
